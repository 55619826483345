<template>
  <div :class="inDrawingTool ? 'no-top-margin-all-descendants' : 'position-fixed'" 
    style="right: 0.938rem; top: calc(4.125rem + 8px)"
    id="drawing-tool-toggle">
    <b-button 
      @click="toggleDrawingMode" 
      :variant="drawingMode ?
        'outline-primary bg-white border-white text-dark' :
        'outline-white border-transparent text-dark'" 
      class="gray-button"
      :disabled="editInsightMode">
      <b-img :src="'/general_icons/Drawing_Icon.webp'" class='mx-1 d-inline-block align-middle' id="drawing-toggle-icon"></b-img>
      <b-form-checkbox 
        id="drawing-tool-switch"
        class="d-inline-block align-middle ml-1"
        switch
        :checked="drawingMode"
        disabled>
      </b-form-checkbox>
    </b-button>
    <b-tooltip target="drawing-tool-toggle" v-if="editInsightMode">
      Cannot use drawing tool while editing insights
    </b-tooltip>
    <b-tooltip target="drawing-tool-toggle" v-else>
      Toggle drawing tool
    </b-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DrawingToolToggle',
  props: {
    inDrawingTool: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('project', ['drawingMode', 'editInsightMode'])
  },
  methods: {
    toggleDrawingMode() {
      this.$emit('toggleDrawingMode');
    }
  }
};
</script>

<style>
#drawing-toggle-icon {
  max-height: 20px;
}

#drawing-tool-switch {
  position: absolute;
  border: none;
  padding: 0;
  width: 0;
  height: 0;
}

label[for=drawing-tool-switch] {
  cursor: pointer;
}

label[for=drawing-tool-switch]::after {
  top: calc(0.25rem + 3px) !important;
}

.gray-button {
  background-color: var(--grey-400);
}

.gray-button:hover {
  background-color: var(--grey-400);
}
</style>